<div id="faq" class="container-fluid">

  <img id="flower-decoration" src="assets/images/flower-decoration.png">
  <img id="grid-decoration" src="assets/images/faq-grid.png">
  <div class="container-1350 ">
    <div class="row">
      <div class="col-lg-4 left-col">
        <h2 class="header-decorator">FAQ</h2>
        <img src="/assets/images/activities-decoration.png">
        <div class="left-strip-continuation"></div>
      </div>

      <div class="col-lg-7 right-col">
        <h2 class="header-decorator">FAQ</h2>
        <h3>Gyakran ismételt kérdések</h3>
        <div class="q-container">
          <div class="question-row" [ngClass]="{'open':dropDowns[0].isOpen}">
            <div class="question" (click)="toggleDropDown(0)">
              <span class="question-label">Meddig is tart pontosan a biztosításom?</span>
              <span class="arrow">
               <svg class="arrow-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256"
                    style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
	<g>
		<polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   "/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
              </span>
            </div>

            <div class="question-content">
              <div class="padding-transition-hack"></div>
              A biztosítási védelem akkor érvényes, ha az esedékes tagdíj még a káresemény előtt be lett fizetve.
              Kivételt jelent minden év január hónapja: Amennyiben a káresemény ebben a hónapban következik be, és erre
              a naptári hónapra a tagdíj még nem került befizetésre, úgy teljesítésre csak akkor kerül sor, ha a
              tagdíjat még befizetik és a tagdíjat az előző évre is befizették. A tagdíj január 31-e utáni befizetése
              esetén a biztosítási védelem a befizetést követő nap nulla órakor kezdődik. Az új tagok is, akik az adott
              év szeptember 1-je után csatlakoznak, a következő január 1-jéig biztosítottnak számítanak, habár erre az
              időszakra nem kerül tagdíj elszámolásra.
            </div>
          </div>

          <div class="question-row" [ngClass]="{'open':dropDowns[1].isOpen}">
            <div class="question" (click)="toggleDropDown(1)">
              <span class="question-label">Milyen összegig vagyok biztosítva, és mikor számíthatok segítségre?</span>
              <span class="arrow">
            <svg class="arrow-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256"
                 style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
	<g>
		<polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   "/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span>
            </div>

            <div class="question-content">

              <div class="padding-transition-hack"></div>
              A tagdíj tartalmazza az Alpenverein Weltweit Service biztosítást, amely az alábbi szolgáltatásokat
              nyújtja:

              <p>→ Mentési költségek bel- és külföldön, nehezen járható terepről történő mentés esetén beleértve a
                belföldi
                áthelyezés és hazaszállítás költségeit
              </p>
              <p>25.000,– EUR összegig személyenként és biztosítási eseményenként;</p>
              <p>Egész évben, az egész világon, a szabadidő alatt; A mentési költségek a helyben működő mentőszervezetek
                (valamint a határ közelében történt események esetében a szomszédos ország mentőszervezeteinek) azon
                költségei, amelyek szükségessé válnak, ha a biztosított személy szükséghelyzetbe kerül / balesetet
                szenved, vagy a hegyekben vagy vizeken vészhelyzetbe kerül és sérülten, vagy sérülés nélkül nehezen
                járható terepről kell kimenteni (értelemszerűen ez érvényes a halálesetre is). A mentési költségek a
                biztosított után folytatott keresés és a nehezen járható terepről való kimentés igazolt költségeit
                jelentik a) a legközelebbi, járművel járható útig vagy b) a baleset helyszínéhez legközelebbi kórházig.
              <p>
              <p>→ Külföldről való hazaszállítás és külföldi gyógykezelés költségei</p>
              <p>Érvényes minden külföldi utazás első nyolc hetében, a világon mindenütt, szabadidős és munkabalesetek,
                valamint betegség esetén,
              <p>
              <ul>
                <li>külföldről való hazaszállítási szolgálatra: összegkorlátozás nélkül</li>
                <li>orvosilag szükséges gyógykezelésekre (beleértve az orvosilag szükséges kórházba szállítás költségeit
                  is)
                  külföldön: 10.000,- EUR összegig.
                </li>
              </ul>
              <p>
                2.1A sérült/megbetegedett személy külföldről való, orvosilag indokolt betegszállításának teljes
                költségei
                a lakóhely szerinti ország egy kórházába vagy a lakóhelyére, ezenkívül a szállított személyhez közelálló
                személy szállításának a költségei is. A hazaszállítás előfeltétele a biztosított személy szállíthatósága
                mellett, a) hogy egészségi állapotában életveszélyes rendellenesség álljon fenn, vagy b) hogy a helyben
                adott orvosi ellátás alapján a hazai színvonalnak megfelelő kezelés nincsen biztosítva, vagy c) hogy
                több,
                mint ötnapos kórházi osztályon való tartózkodásra lehet számítani.
              </p>
              <p>A szállítást az Alpenverein tagkártyáján feltüntetett biztosítótársaságnak kell megszerveznie, különben
                legfeljebb 750,- EUR összeg kerül megtérítésre. Europ Assistance,</p>
              <p>Tel.: +43/1/253 3798, Fax: +43/1/313 89 1304, e-mail: <a href="mailto:aws@alpenverein.at"
                                                                          class="email">aws@alpenverein.at</a></p>
              <p>2.2 A halaszthatatlan, orvosilag szükséges gyógykezelés külföldön (nem a lakóhely szerinti országban)
                keletkezett költségei, beleértve az orvos által felírt gyógyszereket, az orvosilag szükséges, a
                legközelebbi, megfelelő kórházba való szállítás költségei 10.000,- EUR biztosítási összegig, amelyből
                ambuláns gyógykezelésre, beleértve az orvos által felírt gyógyszereket, 2.000,- EUR áll rendelkezésre.
                Ambuláns gyógykezelésekre, beleértve az orvos által felírt gyógyszereket is, személyenként és külföldi
                tartózkodásonként 70,- EUR összegű önrész érvényes. Ez mindig levonásra kerül a Generali Versicherung AG
                biztosítási teljesítéséből, tehát további kötelező vagy magánbiztosításból származó teljesítési
                kötelezettség esetén is. A biztosító az orvosilag szükséges kórházi gyógykezelés igazolt költségeit</p>
              <ul>
                <li>Ausztriában: az állami fenntartású kórházak általános díjosztályában;</li>
                <li>
                  Ausztrián kívül: az állami fenntartású kórházakéban téríti meg. Amennyiben a kórházi gyógykezelés
                  sürgőssége miatt nem lehetséges az állami fenntartású kórház felkeresése vagy a biztosított személynek
                  nem lehetett befolyása a kórház kiválasztására, úgy a biztosító az orvosilag szükséges, nem állami
                  fenntartású kórházban folytatott gyógykezelés igazolt költségeit is megtéríti. Ez a teljesítési
                  kötelezettség abban az időpontban ér véget, amikor orvosilag igazolható az átszállítás egy állami
                  fenntartású kórházba.
                  <p>A 2.2 pont szerinti orvosilag szükséges kórházi gyógykezelés költségeit csak akkor vállalja a
                    biztosító közvetlen elszámolással a biztosítási összeg mértékéig, ha az e-card/EHIC-kártya* a
                    kórházban bemutatásra kerül és a lebonyolítás a Europ Assistance-en keresztül zajlik. Különben
                    legfeljebb 750,- EUR összeg kerül megtérítésre.</p>
                  <p>Amennyiben Ön nem rendelkezik EHIC-kártyával, a kórházi tartózkodás során keletkezett költségek
                    viselésével kapcsolatban haladéktalanul vegye fel a kapcsolatot az
                    Europ Assistance ügyfélszolgálatával.</p>
                  <p>Europ Assistance, Tel.: +43/1/253 3798, Fax: +43/1/313 89 1304,</p>
                  <p>e-mail: <a href="mailto:aws@alpenverein.at" class="email">aws@alpenverein.at</a></p>
                  <p>*Az Európai Egészségbiztosítási Kártyával (EHIC) kapcsolatos információkat az alábbi weboldalon
                    talál:
                    http://ec.europa.eu/social</p>

                </li>
              </ul>
            </div>
          </div>


          <div class="question-row" [ngClass]="{'open':dropDowns[2].isOpen}">
            <div class="question" (click)="toggleDropDown(2)">
              <span class="question-label">Vannak olyan kizáró (sport) tevékenységek, melyeket nem biztosít az Alpenverein?</span>
              <span class="arrow">
            <svg class="arrow-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256"
                 style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
	<g>
		<polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   "/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span>
            </div>
            <div class="question-content">
              <div class="padding-transition-hack"></div>
              <p>A biztosítási védelem nem terjed ki:
              <p>
              <ul>
                <li>Hivatásos vagy egyéb, díjazásért végzett tevékenységek során bekövetkezett
                  balesetekre/betegségekre;
                </li>
                <li>Gépjárművek használata során bekövetkezett balesetekre;</li>
                <li>Az északi és az alpesi sísport, a snowboardozás, valamint a freestyle, freeride, a bob-, a síbob-, a
                  szkeletonverseny, vagy a szánkózás területén rendezett országos, tartományi vagy nemzetközi
                  versenyeken
                  való részvételkor, valamint az erre felkészítő edzés során keletkezett balesetekre;
                </li>
                <li>Biztosított személyek légi vezetői (sportrepülőeszköz vezetői is) minőségük keretében elszenvedett
                  baleseteire;
                </li>
                <li>Rendkívüli kockázattal járó merülésekre (merülések 40 mtől, jégbúvárkodás, búvár expedíciók);</li>
                <li>A Mountainbike területén rendezett versenyekre (Downhill, Four Cross, Dirt Jump), beleértve a
                  hivatalos
                  felkészítő edzéseken és a kvalifikációs versenyeken való részvételt;
                </li>
                <li>A sebesség, merülés és repülés területén tett rekordkísérletekre;</li>
                <li>Expedíciókon való részvételre – Expedíciónak számít az a több naptól hetekig tartó, ritkán
                  látogatott,
                  kiépített infrastruktúra (pl. menedékházak) nélküli területre tett utazás, amely részben felfedező
                  vagy
                  kutató jelleggel bír.
                </li>
              </ul>

            </div>
          </div>

          <div class="question-row" [ngClass]="{'open':dropDowns[3].isOpen}">
            <div class="question" (click)="toggleDropDown(3)">
              <span class="question-label">6000 méter felett is biztosít az Alpenverein?</span>
              <span class="arrow">
            <svg class="arrow-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256"
                 style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
	<g>
		<polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   "/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span>
            </div>
            <div class="question-content">

              <div class="padding-transition-hack"></div>
              <p>
                <b>NEM!</b> 6.000 m-nél magasabb hegyek tervezett megmászásával járó utazások során, valamint az
                Északi-sarkra (úti cél az északi sarkkörön túl), a Déli-sarkra (úti cél a déli sarkkörön túl) és
                Grönlandra tett utazások során bekövetkezett balesetekre / betegségekre, hazaszállítást és orvosi
                gyógykezelést <b>NEM</b> térít meg a biztosítás.
              </p>
              <p>
                Az Alpenverein a 6.000 m magasságot meghaladó hegyek megmászásához külön biztosítást kínál.
                Információkat
                és dokumentumokat a www.alpenverein.at/versicherung címen talál.
              </p>
            </div>
          </div>

          <div class="question-row" [ngClass]="{'open':dropDowns[4].isOpen}">
            <div class="question" (click)="toggleDropDown(4)">
              <span class="question-label">Mit kell tennem, ha baleset ér?</span>
              <span class="arrow">
            <svg class="arrow-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256"
                 style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
	<g>
		<polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   "/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span>
            </div>
            <div class="question-content">

              <div class="padding-transition-hack"></div>
              <p>Figyelem!</p>
              <p>Visszaszállítás, hazaszállítás, külföldi kórházi orvosi gyógykezelés és belföldi áthelyezés előtt (de
                nem mentés esetén) feltétlenül vegye fel a kapcsolatot a 24 órában működő segélyszolgálattal (különben
                legfeljebb 750,- EUR összeg kerül megtérítésre):
                Europ Assistance, Tel.: +43/1/253 3798, Fax: +43/1/313 89 1304,</p>
              <p>e-mail: <a href="mailto:aws@alpenverein.at" class="email">aws@alpenverein.at</a></p>
              <p>Mentés, hazaszállítás, áthelyezés és orvosi gyógykezelés előtt kérjük, tegyen kárbejelentést a
                KNOX Versicherungsmanagement GmbH társaság részére: 6020 Innsbruck, Resselstraße 33</p>
              <p>Tel.: +43/512/238300-30, Fax: +43/512/238300-15,</p>
              <p>e-mail: <a href="mailto:AV-service@knox.co.at" class="email">AV-service@knox.co.at</a></p>
              <p>Kötelező felelősségbiztosítási és jogvédelmi ügyekben kérjük, tegyen kárbejelentést a KNOX
                Versicherungsmanagement GmbH társaság részére: 6020 Innsbruck, Resselstraße 33
              <p>Tel.: +43/512/238300-30, Fax: +43/512/238300-15,</p>
              <p>e-mail: <a href="mailto:AV-service@knox.co.at" class="email">AV-service@knox.co.at</a></p>
              <p>Kárbejelentő lapot online a www.alpenverein.at/versicherung címen talál.</p>

            </div>
          </div>
        </div>

        <div class="more-information-row">
          <a href="/assets/files/Alpenverein_Ismerteto_HU_2023.pdf">Teljes leírás és Felhasználási feltételek →</a>
        </div>

        <div class="mobile-decoration">
          <img src="/assets/images/faq-image-mobile.png">
        </div>
      </div>
    </div>
  </div>
</div>
