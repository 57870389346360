import {Component, OnInit} from '@angular/core';
import {RoutePathEnum} from '../RoutePathEnum';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss']
})
export class OrderPageComponent implements OnInit {


  RoutePathEnum = RoutePathEnum;

  constructor() {
  }

  ngOnInit(): void {
  }

}
