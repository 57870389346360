<div id="membership" class="container-fluid">
  <img id="map-decoration" src="/assets/images/map-decoration.png">
  <div class="container-1350 ">

    <div class="membership-header">
      <h2>Tagsági díjak</h2>
      <div class="header-decorator">Tagsági díjak</div>
    </div>

    <div class="memberships-container">
      <div class="membership-container">
        <div class="membership-col">
          <div class="membership-img">
            <img src="/assets/images/membership-a.png">
          </div>
          <div class="membership-header-3">
            <h3>A-Tagság</h3>
          </div>
          <div class="membership-p">
            28-tól 64 éves korig. Egyedülálló szülőnek, a gyerekek 18 éves korig, a diákok 27 éves korig biztosítva
            vannak.
          </div>
          <div class="membership-price">
            69 Euro /év*
          </div>
        </div>
      </div>
      <div class="membership-container">
        <div class="membership-col">
          <div class="membership-img">
            <img src="/assets/images/membership-b.png">
          </div>
          <div class="membership-header-3">
            <h3>B-Tagság</h3>
          </div>
          <div class="membership-p">
            Élettárs, Szenior (65 évtől), Junior, Diák (27 éves korig).
          </div>
          <div class="membership-price">
            53 Euro /év*
          </div>
        </div>
      </div>
      <div class="membership-container">
        <div class="membership-col">
          <div class="membership-img">
            <img src="/assets/images/membership-c.png">
          </div>
          <div class="membership-header-3">
            <h3>Gyerek és fiatal</h3>
          </div>
          <div class="membership-p">
            18 éves korig.
          </div>
          <div class="membership-price">
            26 Euro /év*
          </div>
        </div>
      </div>
        <div class="membership-container">
        <div class="membership-col">
          <div class="membership-img">
            <img src="/assets/images/membership-c.png">
          </div>
          <div class="membership-header-3">
            <h3>Egyedülálló szülő</h3>
          </div>
          <div class="membership-p">
            Gyerekek 18 éves korig, diákok 27 éves korig ugyancsak biztosítva vannak.
          </div>
          <div class="membership-price">
            69 Euro /év*
          </div>
        </div>
      </div>
      <div class="membership-container">
        <div class="membership-col">
          <div class="membership-img">
            <img src="/assets/images/membership-d.png">
          </div>
          <div class="membership-header-3">
            <h3>Családi Tagság</h3>
          </div>
          <div class="membership-p">
            Családon belül a gyerekek 18 éves korig, azon felül tanulói vagy hallgatói jogviszonyban 27 éves korig
            biztosítva vannak.
          </div>
          <div class="membership-price">
            122 Euro /év*
          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid second">

      <div class="container-1350 ">
        <div class="row">
          <div class="col-12">
            <div id="order-now-button-container">

              <a class="button  grey-background mb-4" (click)="showModal()" fragment="order-container">
                Kedvezménnyel kapcsolatos információk <span class="arrow-left">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
               x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
               xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span></a>
              <a class="button  grey-background" [routerLink]="[RoutePathEnum.order]" fragment="order-container">
                Igényelem most! <span class="arrow-left">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
               x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
               xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span></a>
            </div>
            <p id="notice">
              *Kérjük vegye figyelembe, hogy a világszerte érvényes biztosítás csak a tagsági díj befizetése utáni
              naptári
              napon lép érvénybe. A biztosítás gyerekeknek ingyen: költségmentes tagság teljes körű biztosítással,
              jövedelemmel nem rendelkező gyerekek számára 25 éves korig, diákok számára 27 éves korig, ha mindkét
              szülő
              ugyanazon helyi szervezetnél rendelkezik Alpenverein tagsággal. Egyedülálló szülők gyermekeire is
              érvényes,
              azzal a feltétellel, ha mindekét szülő ugyanazon Alpenverein helyi szervezethez tartozik. 18 éven aluli
              gyerekek számára nem szükséges igazolás az ingyenes tagsághoz. A 19. életévtől kötelező a hallgatói vagy
              tanulói jogviszony igazolása. További információk és a
              <a href="/assets/files/Alpenverein_Ismerteto_2023.pdf">Teljes leírás a Felhasználási feltételeknél</a>
              érhető el.
            </p>

          </div>
        </div>
      </div>

      <div class="right-strip-continuation">

      </div>

    </div>
  </div>
</div>
