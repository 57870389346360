<!--<div id="background-container">
  <img id="background" src="/assets/images/background-desktop.png">
</div>
-->
<div id="absolute-background-container">
  <img id="absolute-background" src="/assets/images/background-desktop.jpg">
</div>
<div class="container-1350">

  <h1> Bármerre is jársz,<br>
    mi <span class="text-decoration-container">vigyázunk <div class="text-decoration"></div></span> rád
  </h1>

  <p>
    Balesetbiztosítás extrémsportra és egyéb szabadidős tevékenységre az egész világra kiterjedően, ráadásul most Európa
    szinten kiemelt kedvezményekkel több mint 1.700 hüttében és menedékházban, valamint egyéb létesítményekben is,
    évente csupán 69 euróért!*
  </p>


  <div class="buttons">
    <a class="button  green-background" [routerLink]="[RoutePathEnum.order]" fragment="order-container"> Igényelem
      most!
      <span class="arrow-left">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
               x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
               xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span>
    </a>
    <a class="button" href="#membership"> Tagsági díjak</a>
  </div>
</div>


<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Őszi akció!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Rendkívül előnyös csatlakozási lehetőség!</p>
        <p>A 2024 szeptember 01. és 2024. december 31. között jelentkezők megkapják egy év áráért a tagságot egészen 2025.12.31-ig. </p>
        <p>Az akció keretein belül a jeletkezők tagdíja a jelentkezés napjától egészen 2025.12.31-ig lesz érvényes. 
          Tehát aki befizeti a normál éves tagdíjat szeptemberben, akkor 16 hónapra lesz érvényes a tagsága.</p>
          <p>Az akció minden korosztályra ezen belül családokra is vonatkozik.</p>

        <p>A tagságok fajtáiról
          <a href="#membership" (click)="closeModal()">ezen a linken</a> tájékozódhattok.</p>


        <p>Még néhány ok, amiért most érdemes csatlakozni rajtunk keresztül:
        <ul>
          <li>nem kell Euros fizetéssel bajlódnod, Forintban is befizetheted a díjat, az általunk megadott Raiffeisen
            bankszámlára,
          </li>
          <li>ha beadod az igényedet az alpenverein tagságra, egy-két napon belül aktiválva lesz a tagságod.</li>
        </ul>

        <p>Csak az lehet az akció keretein belül tag, aki korábban nem rendelkezett aktív
          tagsággal.</p>

        <p class="mb-5">Érdeklődni az <a href="mailto:info@hegyibiztositas.hu?subject=Tagsági kedvezményes jelentkezés"
                                         class="email">info@hegyibiztositas.hu</a> email címen vagy a +36 30 465 4165
          telefonszámon
          lehet.</p>


        <a class="button  grey-background" data-dismiss="modal" [routerLink]="[RoutePathEnum.order]"
           fragment="order-container">
          Igényelem most! <span class="arrow-left">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
               x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
               xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span></a>
      </div>
      <div class="modal-footer">


        <button type="button" class="btn btn-primary" id="modal-button" data-dismiss="modal" aria-label="Close">Bezár
        </button>
      </div>
    </div>
  </div>
</div>
