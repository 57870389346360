import {Component, OnInit} from '@angular/core';
import {RoutePathEnum} from '../RoutePathEnum';


@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {


  RoutePathEnum = RoutePathEnum;

  constructor() {
  }

  ngOnInit(): void {
  }

  showModal() {
    ($('#myModal') as any).modal('show');
  }
}
