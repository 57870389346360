import {AfterViewInit, Component, OnInit} from '@angular/core';
import {RoutePathEnum} from '../RoutePathEnum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit, AfterViewInit {


  RoutePathEnum = RoutePathEnum;

  public isOpened = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    $('#navbarNav').on('show.bs.collapse', function() {
      $('#nav').addClass('opened');
    });

    $('#navbarNav').on('hide.bs.collapse', function() {
      $('#nav').removeClass('opened');
    });

    setTimeout(function() {
      var navMain = $('#navbarNav');
      navMain.on('click', 'a', null, function() {
        navMain.collapse('hide');
      });
    }, 200);

    $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      //>=, not <=
      if (scroll >= 10) {
        //clearHeader, not clearheader - caps H
        $('app-header').addClass('dark-header');
      } else {
        $('app-header').removeClass('dark-header');
      }
    });
  }

}

declare var $: any;
