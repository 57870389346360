<div id="absolute-background-container-order">
  <img id="absolute-background-order" src="/assets/images/background-desktop-order.jpg">
  <img id="absolute-background-order-wide" src="/assets/images/background-desktop-order-wide.jpg">
</div>

<div id="order-container">
  <div class="container-1350">
    <h1>
      Igénylés
      <div class="text-decoration"></div>
    </h1>
  </div>
  <img id="order-upper-separator" src="/assets/images/order-upper-separator.png">
  <div class="order-body-container">
    <div class="container-1350 options-header-container">
      <p id="options">
        Jelenleg az Alpenverein Balesetbiztosítását az alábbi módon lehet megkötni :
      </p>
      <!--
      <h2 id="contract-header" class="order-header">
        Online szerződéskötő felület - Német/angol nyelvű
      </h2>-->
    </div>

    <!--
  <div class="container-1350 contract-body">
    <p id="options-p" class="order-p">
      Az Online Szerződéskötés gombra kattintva átirányítunk a balesetbiztosítási szolgáltatást nyújtó alpenverein.at
      oldalára, ahol a német/angol nyelvű kérdőívet kitöltve elindul a regisztrációs folyamat.
    </p>
    <a class="button contract-button green-background" target="_blank"
       href="https://www.alpenverein.at/portal/mitgliedwerden/mw.php?sid=c9d732f2-7ccd-4c94-8f65-1140bd6ba2ecbudapest-flash">Online
      Szerződéskötés
      <span class="arrow-left">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
             x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
             xml:space="preserve">
<g>
<g>
  <path
    d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
      </span>
    </a>
  </div>-->
    <div class="container-1350 terms-header-container">
      <h2 id="terms-header" class="order-header">
        Tagsági Nyilatkozat letöltése és a kitöltést követő visszaküldése
      </h2>
    </div>
    <div class="container-1350 terms-body">
      <p id="contract-p" class="order-p">
        A Tagsági Nyilatkozat letöltése gombra kattintva a magyar nyelvű Tagsági Nyilatkozat letöltésre kerül PDF
        formátumban, melyet ki kell nyomtatni, kézzel kitölteni, majd a bescannelést követően vissza kell küldeni az
        alábbi emailcímre: <a href="mailto:info@hegyibiztositas.hu?subject=Tagsági Nyilatkozat" class="email">info@hegyibiztositas.hu</a>
      </p>
      <a class="button terms-button green-background mb-5" target="_blank"
         href="/assets/files/Alpenverein_Tagsagi_Nyilatkozat_HU_2023.pdf">Tagsági Nyilatkozat letöltése
        <span class="arrow-left">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
             x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
             xml:space="preserve">
<g>
<g>
  <path
    d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
        </span>
      </a>
      <p class="mb-5">Érdeklődni az <a href="mailto:info@hegyibiztositas.hu?subject=Tagsági kedvezményes jelentkezés"
                                       class="email">info@hegyibiztositas.hu</a> email címen vagy a +36 30 465 4165
        telefonszámon
        lehet.</p>

    </div>

  </div>
</div>
