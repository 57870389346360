import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RoutePathEnum} from './RoutePathEnum';
import {MainComponent} from './main/main.component';
import {OrderComponent} from './order/order.component';
import {OrderPageComponent} from './order-page/order-page.component';

const routes: Routes = [{
  path: RoutePathEnum.root,
  component: MainComponent
},
  {
    path: RoutePathEnum.order,
    component: OrderPageComponent
  },];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
