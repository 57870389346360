import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {LandingComponent} from './landing/landing.component';
import {AboutUsComponent} from './about-us/about-us.component';
import { MembershipComponent } from './membership/membership.component';
import { FaqComponent } from './faq/faq.component';
import { DamageReportComponent } from './damage-report/damage-report.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { OrderComponent } from './order/order.component';
import { OrderPageComponent } from './order-page/order-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingComponent,
    AboutUsComponent,
    MembershipComponent,
    FaqComponent,
    DamageReportComponent,
    FooterComponent,
    MainComponent,
    OrderComponent,
    OrderPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
