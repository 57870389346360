import { Component, OnInit } from '@angular/core';
import { RoutePathEnum } from '../RoutePathEnum';

@Component({
  selector: 'app-damage-report',
  templateUrl: './damage-report.component.html',
  styleUrls: ['./damage-report.component.scss']
})
export class DamageReportComponent implements OnInit {

  RoutePathEnum = RoutePathEnum;
  constructor() { }

  ngOnInit(): void {
  }

}
