<script src="https://unpkg.com/colyseus.js@^0.14.0/dist/colyseus.js"></script>
<div id="navbar-container-fluid">
  <img id="mobile-menu-map" src="/assets/images/mobile-menu-map.png">
  <div class="container" id="navbar-container">
    <nav id="nav" class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="#">
        <img id="header-logo" src="/assets/images/header-logo.png"
             class="d-inline-block align-top"
             alt="" loading="lazy">
        <span><!--enlight--></span>
      </a>
      <!--
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      -->
      <div class="navTrigger navbar-toggler collapsed"
           data-toggle="collapse" data-target="#navbarNav"
           aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i></i><i></i><i></i>
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" id="header-mobile-logo">
            <a class="nav-link" href="" i18n>
              <img src="/assets/images/header-logo.png">
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link"
               [routerLink]="['']"
               fragment="about-us"
               i18n>Rólunk</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['']"
               fragment="membership" i18n>Tagsági díjak</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['']"
               fragment="faq" i18n>FAQ</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="/assets/files/Alpenverein_Ismerteto_HU_2023.pdf" i18n>Felhasználási
              feltételek</a>
          </li>
          <li class="nav-item  button-item">
            <a class="nav-link"
               [routerLink]="['']"
               fragment="damage-report" i18n>Kárbejelentés</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

</div>
