<div class="container-fluid first" id="about-us">

  <div class="container-1350 ">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-5">
          <h2>Rólunk</h2>
          <img id="chopper" src="/assets/images/chopper.png" alt="heli">
        </div>

        <div class="col-lg-7" id="about-us-col">
          <h3>Mi is az az Alpenverein?</h3>
          <p>Az Alpenverein Weltweit Service (Az Alpesi Egyesület Egész Világra Kiterjedő Ügyfélszolgálata) az Egyesület
            minden tagjának biztosítási védelmet nyújt a szabadidős tevékenységek során bekövetkezett balesetekre a
            mentés
            területén. A külföldi fedezeti védelem a hazaszállítás és a gyógykezelés terén a szabadidős és a
            munkabalesetek,
            valamint betegség esetén érvényes. Az Alpenverein Weltweit Service az egész világra kiterjedően érvényes, a
            kötelező felelősségbiztosítás és a büntetőjogi jogvédelmi biztosítás kivételével, amelyek Európára
            korlátozódnak. Az Alpenverein Weltweit Service biztosítója a Generali Versicherung AG. A tagdíj tartalmazza
            az
            Alpenverein Weltweit Service biztosítást.
          </p>
          <a class="details" href="/assets/files/Alpenverein_Ismerteto_HU_2023.pdf">Teljes leírás és Felhasználási
            feltételek </a>

        </div>

      </div>
    </div>

    <div class="right-strip-continuation">

    </div>
  </div>

</div>
<img id="chopper-responseive" src="/assets/images/chopper-mobile.png" alt="heli">
<div class="container-fluid second">
  <img id="map-desktop" src="/assets/images/map-about-us.png">
  <img id="map-mobile" src="/assets/images/map-mobile.png">
  <div class="container-1350 second">
    <div class="row">

      <div class="col-lg-6 offers-col">
        <h3>Mit nyújt az Alpenverein?</h3>
        <p class="subtext">A tagdíj tartalmazza az Alpenverein Weltweit Service biztosítást, amely az alábbi
          szolgáltatásokat nyújtja:
        </p>
        <div class="list">
          <div class="list-item">
            <div class="list-icon">
              <span class="arrow-left bullet">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Layer_1"
                     x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
                     xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
              </span>
            </div>
            <div class="list-content">
              Mentési költségek bel- és külföldön, nehezen járható terepről történő mentés esetén beleértve a belföldi
              áthelyezés és hazaszállítás költségeit
            </div>
          </div>

          <div class="list-item">
            <div class="list-icon">
              <span class="arrow-left bullet">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Layer_1"
                     x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
                     xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
              </span>
            </div>
            <div class="list-content">
              Külföldről való hazaszállítás és külföldi gyógykezelés költségei
            </div>
          </div>

          <div class="list-item">
            <div class="list-icon">
              <span class="arrow-left bullet">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Layer_1"
                     x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
                     xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
              </span>
            </div>
            <div class="list-content">
              Európa Kötelező Felelősségbiztosítás
            </div>
          </div>

          <div class="list-item">
            <div class="list-icon">
              <span class="arrow-left bullet">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Layer_1"
                     x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
                     xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
              </span>
            </div>
            <div class="list-content">
              Európa Büntetőjogi Jogvédelem
            </div>
          </div>

          <div class="list-item">
            <div class="list-icon">
              <span class="arrow-left bullet">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Layer_1"
                     x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
                     xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
              </span>
            </div>
            <div class="list-content">
              Európa Kártérítési Jogvédelem
            </div>
          </div>
        </div>


        <a class="button  green-background"
           [routerLink]="[RoutePathEnum.order]" fragment="order-container"> Igényelem most!
          <span class="arrow-left">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
               x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
               xml:space="preserve">
<g>
	<g>
		<path
      d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
          </span>
        </a>
      </div>

      <div class="col-lg-5 col-md-12">
        <img id="rescue-team-image" src="/assets/images/rescue-team.png">
      </div>
    </div>

  </div>
  <img id="snow-separator" src="/assets/images/snow-separator.png">

</div>

