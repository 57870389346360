<div class="container-fluid" id="damage-report">
  <img id="damage-report-background" src="/assets/images/bottom-background.jpg">
  <div class="container-1350">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 left-col">
          <div class="header-decorator">Kárbejelentés</div>
          <h2 class="col-row">Kárbejelentés</h2>
          <div class="col-row content"><span class="bold">Figyelem!</span> Visszaszállítás, hazaszállítás, külföldi
            kórházi orvosi gyógykezelés
            és belföldi áthelyezés előtt (de nem mentés esetén) feltétlenül vegye fel a kapcsolatot a 24 órában működő
            segélyszolgálattal!
            <br><br>
            A szállítást az alábbi biztosító társaságnak kell megszerveznie, különben legfeljebb 750,-EUR összeg kerül
            megtérítésre:
          </div>
          <div class="col-row assistance">Europ Assistance
          </div>
          <div class="col-row contacts">
            <div class="col-lg-6 contact">
              <div class="upper-text">0-24 elérhető segélyszolgálat</div>
              <div class="lower-text">+43 1 253 3798</div>
            </div>
            <div class="col-lg-6 contact">

              <div class="upper-text">0-24 elérhető Fax szám</div>
              <div class="lower-text">+43 1 313 89 1304</div>
            </div>
            <div class="col-lg-6 contact">

              <div class="upper-text">0-24 elérhető e-mail cím</div>
              <div class="lower-text">aws@alpenverein.at</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 right-col">
          <div class="col-row content">
            Mentés, hazaszállítás, áthelyezés és orvosi gyógykezelés előtt, valamint Kötelező felelősségbiztosítási és
            jogvédelmi ügyekben kérjük, tegyen kárbejelentést:
          </div>
          <div class="col-row assistance">KNOX Versicherungsmanagement GmbH
            <div class="address">
              6020 Innsbruck, Resselstraße 33
            </div>
          </div>
          <div class="col-row contacts">
            <div class="col-lg-6 contact">
              <div class="upper-text">Telefonszám</div>
              <div class="lower-text">+43 512 2383 0030</div>
            </div>
            <div class="col-lg-6 contact">
              <div class="upper-text">Fax szám</div>
              <div class="lower-text">+43 512 2383 0015</div>
            </div>
            <div class="col-lg-6 contact">
              <div class="upper-text">E-mail cím</div>
              <div class="lower-text">AV-service@knox.co.at</div>
            </div>

          </div>

          <div class="col-row notice">
            Fontos, hogy a biztosítás fedezete alá eső szolgáltatásokkal kapcsolatos számlák kiegyenlítése előtt vegye
            fel
            a kapcsolatot a biztosítóval!
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
