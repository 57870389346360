<div id="footer" class="container-fluid">
  <div class="container-1350">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 logo"><img src="assets/images/header-logo.png"></div>

        <div class="col-lg-7 sections">

          <div class="row">
            <div class="col-lg-2 content-link bold">
              <a
                [routerLink]="['']"
                fragment="about-us">
                Rólunk
              </a>
            </div>
            <div class="col-lg-2 content-link">
              <a [routerLink]="['']"
                 fragment="membership">Tagsági díjak</a>
            </div>
            <div class="col-lg-2 content-link">
              <a [routerLink]="['']"
                 fragment="faq">FAQ</a>
            </div>
            <div class="col-lg-2 content-link">
              <a [routerLink]="['']"
                 fragment="damage-report">Kárbejelentés</a>
            </div>
            <div class="col-lg-3 content-link">
              <a target="_blank" href="/assets/files/Alpenverein_Ismerteto_HU_2023.pdf">Felhasználási feltételek</a>
            </div>
          </div>

        </div>

        <div class="col-lg-2 rights-col">
          <span>© All rights reserved.</span>
        </div>
      </div>
    </div>
  </div>
</div>
