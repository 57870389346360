import {Component, OnInit} from '@angular/core';
import {RoutePathEnum} from '../RoutePathEnum';
import {DropdownModel} from './models/DropdownModel';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {


  RoutePathEnum = RoutePathEnum;
  public dropDowns: Array<DropdownModel> = new Array<DropdownModel>();

  constructor() {
    for (let i = 0; i < 10; i++) {
      this.dropDowns.push(new DropdownModel());
    }
  }

  ngOnInit(): void {
  }

  toggleDropDown(index: number): void {
    console.log('toggling', index);
    this.dropDowns[index].isOpen = !this.dropDowns[index].isOpen;
  }

}
