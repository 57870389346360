import { Component, OnInit } from '@angular/core';
import { RoutePathEnum } from '../RoutePathEnum';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {


  RoutePathEnum = RoutePathEnum;

  constructor() { }

  ngOnInit(): void {
  }

}
